import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";

// Context
import { PageType } from "../components/context/page-type";

// Icons
import { CloseFiltersIcon } from "../components/icons/close-filters-icon";

// Utils
import kebabCase from "lodash.kebabcase";

const Page = styled.div`
  min-height: calc(${(props) => props.height}px - 215px - 20px);

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #e9cfc0;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 0 0;
  }

  & .buttons-container {
    width: 100%;
    margin: 40px 0 0 0;

    align-self: flex-end;

    & a {
      height: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      padding: 0 15px;
      margin: 0 20px 0 0;

      width: fit-content;

      line-height: 43px;
      text-align: center;

      border: 1px solid #000000;
      transition: 250ms border-color ease, 250ms background-color ease;

      & svg {
        margin: 0 15px 0 0;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87a3b4;
          background-color: #87a3b4;
        }
      }

      @media (max-width: 800px) {
        height: 30px;
        min-width: 70px;

        line-height: 30px;

        padding: 0 9px;

        & svg {
          width: 7px;
          height: auto;
        }
      }
    }
  }

  & .title-container {
    grid-column: 1 / 13;
    margin: 0 0 2em 0;

    & h1 {
      font-size: 16px;
      line-height: 22px;

      @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 10px;
        line-height: 14px;
      }
    }

    & p {
      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  & .left-column {
    min-height: calc(${(props) => props.height}px - 215px - 20px - 62px);
  }

  & .right-column {
    height: 100%;
    // height: calc(${(props) => props.height}px - 215px - 62px);
  }

  & .left-column {
    grid-column: 1 / 8;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1350px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 1100px) {
      grid-column: 1 / 10;
    }

    @media (max-width: 900px) {
      grid-column: 1 / 13;
    }

    & .results-container {
      width: 100%;
    }
  }

  & .right-column {
    grid-column: 10 / 13;
    position: relative;

    & .active-search-image {
      width: 100%;
      /* height: 100%; */
      /* height: calc(${(props) => props.height}px - 98px); */

      position: absolute;
      // position: sticky;
      top: 75px;
      bottom: 0;

      & img {
        width: 100%;
        height: 100%;
        position: absolute;

        bottom: 0;
        object-fit: contain;
        object-position: bottom right;
      }
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  // align-content: flex-start;

  min-height: calc(${(props) => props.height}px - 215px);

  & .content-grid {
    grid-column: 1 / 13;
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;
    }
  }
`;

const NoResults = styled.div`
  grid-column: 1 / 13;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & a {
    transition: color 250ms ease;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #91a8b7;
      }
    }
  }
`;

const SingleSearchResult = styled.div`
  position: relative;

  width: fit-content;

  & img {
    width: 100%;
    height: 100%;

    &.landscape {
      object-fit: contain !important;
      object-position: bottom;
    }

    &.portrait {
      object-fit: contain !important;
      width: fit-content;
      margin: 0 auto;
    }
  }

  & a {
    display: block;
    width: fit-content;
  }

  & .text-container {
    display: grid;
    grid-template-columns: 150px 1fr;

    @media (max-width: 500px) {
      grid-template-columns: 100px 1fr;
    }

    width: fit-content;

    & .result-type {
      width: 100px;

      & p {
        font-size: 14px;
        line-height: 28px;

        transition: 250ms color ease;
      }
    }

    & .title {
      & h1,
      & h2 {
        font-size: 20px;
        line-height: 28px;

        color: #000;

        transition: 250ms color ease;

        display: inline;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 24px;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      & h1 {
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .sold-out {
    display: inline-block;
    margin: 0 0 0 10px;

    width: 9px;
    height: 9px;

    border-radius: 100%;
    background-color: #f1624e;

    @media (max-width: 800px) {
      margin: 3px 0 0 0;

      width: 7px;
      height: 7px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & .text-container {
        & h1,
        & h2,
        & p {
          color: #91a8b7;
        }
      }
    }
  }

  @media (max-width: 787px) {
    margin: 0 0 1em 0;
  }
`;

const SearchPage = ({ location }) => {
  const [searchResults, updateSearchResults] = useState([]);
  const [activeSearchResult, setActiveSearchResult] = useState(null);
  const [pageType, setPageType] = useContext(PageType);

  const height = use100vh;

  useEffect(() => {
    setPageType({
      page: `search`,
      background: `#E9CFC0`,
      banner: false,
      bannerBackground: `#E9CFC0`,
      fillColor: `#91A8B7`,
      hoverColor: `#91A8B7`,
      underlineColor: `#000`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      const results = location.state.searchResults;

      const artists = results
        .filter((result) => result.type === `artist`)
        .sort((a, b) =>
          b.data.title.text
            .split(" ")[1]
            .localeCompare(a.data.title.text.split(" ")[1])
        )
        .reverse();

      const ourAlphabet = results
        .filter((result) => result.type === `glossary`)
        .sort((a, b) =>
          a.data.section_title.text.localeCompare(b.data.section_title.text)
        );

      const artworks = results
        .filter((result) => result.type === `artwork`)
        .filter((result) => result.data.metafields.length >= 1)
        .sort((a, b) =>
          b.data.metafields
            .filter((field) => field.key === `artist`)[0]
            .value.split(" ")[1]
            .localeCompare(
              a.data.metafields
                .filter((field) => field.key === `artist`)[0]
                .value.split(" ")[1]
            )
        )
        .reverse();

      const finalResults = [...artists, ...artworks, ...ourAlphabet];

      updateSearchResults(finalResults);
    } else {
      return null;
    }
  }, [location]);

  const getMetafield = (artwork, fieldKey) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const metafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === fieldKey)
            .map((field) => field.value)
        : null;

    if (metafield !== null && metafield.length >= 1) {
      return metafield[0];
    } else {
      return null;
    }
  };

  const getArtistName = (artwork) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const artistMetafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === "artist")
            .map((field) => field.value)
        : null;

    if (artistMetafield !== null && artistMetafield.length >= 1) {
      return `/${kebabCase(artistMetafield[0])}`;
    } else {
      return ``;
    }
  };

  if (
    location !== null &&
    location.state !== null &&
    location.state !== undefined
  ) {
    return (
      <>
        <Page height={height}>
          <Grid height={height}>
            {location !== null &&
            location.state !== null &&
            location.state !== undefined ? (
              <>
                <Helmet
                  title={`Search Results for '${location.state.searchQuery}'`}
                  meta={[
                    {
                      name: "og:title",
                      content: `Search Results for '${location.state.searchQuery}'`,
                    },
                  ]}
                />

                <div className="header" />

                {searchResults.length === 0 ? (
                  <NoResults>
                    <div className="title-container">
                      <h1>
                        We are sorry, no results were found for ‘
                        {location.state.searchQuery}’
                      </h1>

                      <p>
                        Check for spelling or try a different search term.
                        <br />
                        Need more help?{" "}
                        <a href="mailto:info@raweditions.com">Email us!</a>
                      </p>
                    </div>

                    <div className="buttons-container">
                      <Link to={`/shop/`}>
                        <CloseFiltersIcon />
                        Close search
                      </Link>
                    </div>
                  </NoResults>
                ) : (
                  <div className="content-grid">
                    <Grid height={height}>
                      <div className="title-container">
                        <h1>
                          Search results for ‘{location.state.searchQuery}’
                        </h1>
                      </div>

                      <div className="left-column">
                        <div className="results-container">
                          {searchResults !== null &&
                            searchResults !== undefined &&
                            searchResults.map((result, outerIndex) => {
                              if (result.type === "glossary") {
                                return (
                                  <SingleSearchResult
                                    key={`single_glossary_${result.id}_${outerIndex}`}
                                    className="glossary"
                                  >
                                    <Link
                                      to={`/our-alphabet/`}
                                      state={{
                                        letter:
                                          result.data.section_title.text.charAt(
                                            0
                                          ),
                                        item: result.data.section_title.text,
                                      }}
                                    >
                                      <div className="text-container">
                                        <div className="result-type">
                                          <p>Our Alphabet</p>
                                        </div>
                                        <div className="title">
                                          <h1>
                                            {result.data.section_title.text}
                                          </h1>
                                        </div>
                                      </div>
                                    </Link>
                                  </SingleSearchResult>
                                );
                              }

                              if (result.type === "artwork") {
                                return (
                                  <SingleSearchResult
                                    key={`single_artwork_${result.id}_${outerIndex}`}
                                    className="artwork"
                                    onMouseEnter={() =>
                                      setActiveSearchResult(result)
                                    }
                                    onMouseLeave={() =>
                                      setActiveSearchResult(null)
                                    }
                                  >
                                    <Link
                                      to={`${getArtistName(result.data)}/${
                                        result.url
                                      }/`}
                                    >
                                      <div className="text-container">
                                        <div className="result-type">
                                          <p>Artwork</p>
                                        </div>
                                        <div className="title">
                                          <h1>
                                            {getMetafield(
                                              result.data,
                                              "artist"
                                            )}
                                          </h1>
                                          {", "}
                                          <h2>{result.data.title}</h2>

                                          {result.data.totalInventory < 1 && (
                                            <span className="sold-out" />
                                          )}
                                        </div>
                                      </div>
                                    </Link>
                                  </SingleSearchResult>
                                );
                              }

                              if (result.type === "artist") {
                                return (
                                  <SingleSearchResult
                                    key={`single_artist_${result.id}_${outerIndex}`}
                                    className="artist"
                                    onMouseEnter={() =>
                                      setActiveSearchResult(result)
                                    }
                                    onMouseLeave={() =>
                                      setActiveSearchResult(null)
                                    }
                                  >
                                    <Link to={result.url}>
                                      <div className="text-container">
                                        <div className="result-type">
                                          <p>Artist page</p>
                                        </div>
                                        <div
                                          className="title"
                                          dangerouslySetInnerHTML={{
                                            __html: result.data.title.html,
                                          }}
                                        />
                                      </div>
                                    </Link>
                                  </SingleSearchResult>
                                );
                              }
                            })}
                        </div>

                        <div className="buttons-container">
                          <Link to={`/shop/`}>
                            <CloseFiltersIcon />
                            Close search
                          </Link>
                        </div>
                      </div>

                      <div className="right-column">
                        <div className="active-search-image">
                          {searchResults !== null &&
                            searchResults !== undefined &&
                            activeSearchResult !== null && (
                              <>
                                {activeSearchResult.type === "artist" && (
                                  <>
                                    {activeSearchResult.data.thumbnail.fluid !==
                                      undefined && (
                                      <img
                                        srcSet={
                                          activeSearchResult.data.thumbnail
                                            .fluid.srcSet
                                        }
                                        src={
                                          activeSearchResult.data.thumbnail
                                            .fluid.src
                                        }
                                        loading={`lazy`}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                        draggable="false"
                                      />
                                    )}
                                  </>
                                )}

                                {activeSearchResult.type === "artwork" && (
                                  <>
                                    {activeSearchResult.data.images.length >=
                                      1 && (
                                      <>
                                        {activeSearchResult.data.images[0]
                                          .src !== null && (
                                          <img
                                            src={
                                              activeSearchResult.data.images[0]
                                                .src
                                            }
                                            alt={activeSearchResult.data.title}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            draggable="false"
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </Grid>
                  </div>
                )}
              </>
            ) : (
              <div className="title-container">
                <NoResults>
                  <div className="title-container">
                    <h1>We are sorry, no results were found</h1>

                    <p>
                      Check for spelling or try a different search term.
                      <br />
                      Need more help?{" "}
                      <a href="mailto:info@raweditions.com">Email us!</a>
                    </p>
                  </div>
                </NoResults>
              </div>
            )}
          </Grid>
        </Page>
      </>
    );
  } else {
    return (
      <>
        <Helmet
          title={`Search Results`}
          meta={[
            {
              name: "og:title",
              content: `Search Results`,
            },
          ]}
        />
        <Page>
          <Grid>
            <div className="title-container">
              <NoResults>
                <div className="title-container">
                  <h1>We are sorry, no results were found</h1>

                  <p>
                    Check for spelling or try a different search term.
                    <br />
                    Need more help?{" "}
                    <a href="mailto:info@raweditions.com">Email us!</a>
                  </p>
                </div>
              </NoResults>
            </div>
          </Grid>
        </Page>
      </>
    );
  }
};

export default SearchPage;
